import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
import Rating from "react-rating";

function SkillShow(props) {
    return (
        <Container>
            <Row>
                <Col md={4} className="skill-show-name">
                    {props.name}
                </Col>
                <Col md={6}>
                    <Rating
                        // emptySymbol="fa fa-star-o fa-2x"
                        // fullSymbol="fa fa-star fa-2x"
                        fractions={2}
                        initialRating={props.rating}
                        readonly
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default SkillShow;