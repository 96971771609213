import * as React from "react";
import { Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './../css/WorkInProgress.css'

function WorkInProgress() {

    const { t } = useTranslation();

    return (
        <Container className="workInProgress">
            <Row>
                {/* <Col md={3} />
                <Col md={6}> */}
                    <h1 className="workInProgress-page-not-found">{t('workInProgress.pageNotFound')}</h1>
                    <h2 className="workInProgress-title">{t('workInProgress.title')}</h2>
                    <p className="workInProgress-description">{t('workInProgress.description')}</p>
                {/* </Col> */}
                {/* <Col md={2} /> */}
            </Row>
        </Container>
    );
}

export default WorkInProgress;