import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Portfolio from "./views/Portfolio";
import NavigationBar from "./components/utils/NavigationBar";
import WorkInProgress from './views/WorkInProgress'
import Footer from './components/utils/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Style.css"

function App() {
  
  document.body.style = 'background: #b2ecfa;';

  return (
    <div className="App">
      <NavigationBar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="*" element={<WorkInProgress />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
