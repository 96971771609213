import * as React from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GoLinkExternal } from "react-icons/go"
import { Link } from "react-router-dom";

function CardProject(props) {

    const { t } = useTranslation();

    return (
      <Card className="card-project-simple">
            <Card.Img variant="top" src={props.imgPath} alt="card-img" />
            <Card.Body>
                <Card.Title style={{fontWeight: "bold"}}>{t("projects." + props.projectName + ".title")}</Card.Title>
                <Card.Text className="card-project-simple-techno">Technology: {t('projects.' + props.projectName + '.technologies')}</Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                {t('projects.' + props.projectName + '.jobDescription')}
                </Card.Text>
                {props.hasUrl ? 
                    <Row>
                        <Col md={6}>
                            <Button variant="primary" href={props.url} target="_blank" className="card-project-button">
                                <GoLinkExternal /> &nbsp;
                                Link
                            </Button>
                        </Col>
                        <Col md={6}>
                            <Link to="/portfolio" >
                                <Button variant="primary" className="card-project-button">
                                    <GoLinkExternal /> &nbsp;
                                    See more
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    :
                    <Link to="/portfolio" >
                        <Button variant="primary" className="card-project-button">
                            <GoLinkExternal /> &nbsp;
                            See more
                        </Button>
                    </Link>
                }
            </Card.Body>
      </Card>
  );
}

export default CardProject;