import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import CardProject from "./CardProject";
import { SHORTPROJECTS } from "../Constants";

function HomePortfolio() {

  const { t } = useTranslation();

  return (
      <Container fluid className="home-portfolio">
          <Container>
              <h1 className="home-portfolio-title">{t('homePortfolio.title')}</h1>
              <p className="home-portfolio-description">{t('homePortfolio.shortDescription')}</p>
              <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                  {/* {SHORTPROJECTS.map((project, index) => ( */}
                  {SHORTPROJECTS.map((project, index) => (
                      <Col md={4} className="home-portfolio-card" key={index}>
                            <CardProject
                                imgPath={project.image}
                                projectName={project.name}
                                hasUrl={project.hasExternalUrl}
                                url={project.url}
                            />
                      </Col>
                  ))}
                    {/* <CardProject
                        imgPath="./assets/seyes/logo-Seyes.png"
                        projectName="achatProStage"
                    /> */}
              </Row>
          </Container>
      </Container>
  );
}

export default HomePortfolio;