import LogoSeyes from "./assets/seyes/logo-Seyes.png";
import LogoGifted from "./assets/studioPitaya/logo-Gifted.png";
import LogoPitaya from "./assets/studioPitaya/logo-StudioPitaya.png";
import LogoAchatPro from "./assets/seyes/logo-ap.png";
import LogoDoggE from "./assets/dogg-e/Dogg-E.png";
import LogoEpitech from "./assets/epitech/logo-Epitech.png";
import LogoGGJ from "./assets/GGJ/logo-GGJ.png";

export const PROJECTS = [
    {
        image: LogoSeyes,
        listImage: [LogoSeyes, LogoAchatPro],
        name: "achatProStage",
        url: "https://seyes.re/",
        hasExternalUrl: true,
    },
    {
        image: LogoDoggE,
        listImage: [LogoDoggE],
        name: "dogge",
        url: "",
        hasExternalUrl: false,
    },
    {
        image: LogoAchatPro,
        listImage: [LogoSeyes, LogoAchatPro],
        name: "achatProPartTime",
        url: "https://achatpro.re/",
        hasExternalUrl: true,
    },
    {
        image: LogoGifted,
        listImage: [LogoGifted, LogoPitaya],
        name: "giftedStage",
        url: "https://pitaya.games/",
        hasExternalUrl: true,
    },
    {
        image: LogoEpitech,
        listImage: [LogoEpitech],
        name: "epitechRtype",
        url: "",
        hasExternalUrl: false,
    },
    {
        image: LogoGGJ,
        listImage: [LogoGGJ],
        name: "ggj2021",
        url: "",
        hasExternalUrl: false,
    },
];

export const SHORTPROJECTS = [
    {
        image: LogoSeyes,
        name: "achatProStage",
        url: "https://seyes.re/",
        hasExternalUrl: true,
    },
    {
        image: LogoDoggE,
        name: "dogge",
        url: "",
        hasExternalUrl: false,
    },
    {
        image: LogoAchatPro,
        name: "achatProPartTime",
        url: "https://achatpro.re/",
        hasExternalUrl: true,
    },
    {
        image: LogoGifted,
        name: "giftedStage",
        url: "https://pitaya.games/",
        hasExternalUrl: true,
    },
    {
        image: LogoEpitech,
        name: "epitechRtype",
        url: "",
        hasExternalUrl: false,
    },
    {
        image: LogoGGJ,
        name: "ggj2021",
        url: "",
        hasExternalUrl: false,
    },
];

export const SKILLS = [
    { name: "ReactJs", rating: 3.5},
    { name: "React-Native", rating: 2.5},
    { name: "JavaScript", rating: 3.5 },
    { name: "C | CPP", rating: 4 },
    { name: "PHP | Symfony", rating: 4},
    { name: "Html | CSS", rating: 3},
    { name: "MySQL", rating: 3},
    { name: "Unity | C#", rating: 3.5},
    { name: "Unreal Engine 4", rating: 3},
    { name: "Git", rating: 4.5 },
  ];

export const SHORTSKILLS = [
    { name: "C | CPP", rating: 4 },
    { name: "PHP | Symfony", rating: 3.5},
    { name: "Unity | C#", rating: 3},
    { name: "ReactJs", rating: 3},
];
  
export const TOOLS = [
    "Visual Studio Code",
    "Git",
    "Unity",
    "Unreal Engine 4",
    "Npm (Node Package Manager)",
    "Composer",
    "Linux",
    "Postman"
];

export const SHORTTOOLS = [
    "Visual Studio Code",
    "Git",
    "Unity",
    "Linux"
];