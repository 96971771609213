import * as React from "react";
import HomeMainTitle from '../components/HomeMainTitle';
import HomePortfolio from '../components/HomePortfolio';
import HomeSkills from "../components/HomeSkills";
import { Container } from "react-bootstrap";


function Home() {
  return (
    <Container className="home-section" id="home">
      <HomeMainTitle/>
      <HomePortfolio/>
      <HomeSkills/>
    </Container>
  );
}

export default Home;