import * as React from "react";
import ProfileSkills from "../components/ProfileSkills";
import CardAbout from "../components/CardAbout";
import { Container } from "react-bootstrap";

function Profile() {
  return (
    <Container className="home-section" id="profile">
      <CardAbout />
      <ProfileSkills />
    </Container>
  );
}

export default Profile;