import * as React from "react";
import { Card, } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { GoLinkExternal } from "react-icons/go"
// import { Link } from "react-router-dom";
import { GiSwordman, GiSwordWound, GiMountainClimbing  } from "react-icons/gi";
import { MdSportsMotorsports } from "react-icons/md";

function CardAbout() {

    const { t } = useTranslation();

    return (
      <Card className="card-about">
            <Card.Body>
                {/* <Card.Title style={{fontWeight: "bold"}}>{t("projects." + props.projectName + ".title")}</Card.Title> */}
                <Card.Text className="card-about-myname">{t('about.title')}<strong>Lucas Techer</strong></Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                    <p>
                    {t('about.description.part1')} <br/>
                    {t('about.description.part2')} <br/><br/>
                    {t('about.description.part3')} <br/>
                    {t('about.description.part4')} <br/>
                    {t('about.description.endpart')} <br/>
                    </p>
                </Card.Text>
                <Card.Text style={{ textAlign: "justify" }}>
                    <ul>
                        <li className="card-about-activity">
                            <GiSwordman/> {t('about.activity1')}
                        </li>
                        <li className="card-about-activity">
                            <GiMountainClimbing/> {t('about.activity2')}
                        </li>
                        <li className="card-about-activity">
                            <MdSportsMotorsports/> {t('about.activity3')}
                        </li>
                        <li className="card-about-activity">
                            <GiSwordWound/> {t('about.activity4')}
                        </li>
                    </ul>
                </Card.Text>
            </Card.Body>
      </Card>
  );
}

export default CardAbout;