import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SKILLS, TOOLS } from "../Constants";
import SkillShow from "./utils/SkillShow";

function ProfileSkills() {

  const { t } = useTranslation();

  return (
      <Container fluid className="home-skills">
          <Container>
              <h1 className="home-portfolio-title">{t('homeSkills.title')}</h1>
              <p className="home-skills-description">{t('homeSkills.shortDescription')}</p>
              <Row className="home-skills-show">
                  <Col className="home-skills-show-col">
                        <h2 className="home-skill-tile">{t('homeSkills.skills')}</h2>
                        {SKILLS.map((skill, index) => (
                            <SkillShow
                                name={skill.name}
                                rating={skill.rating}
                            />
                        ))}
                  </Col>
                  <Col className="home-skills-show-col">
                        <h2 className="home-skill-tile">{t('homeSkills.tools')}</h2>
                        {TOOLS.map((tool, index) => (
                            <ul style={{ "margin-left": "10%" }}>
                                <li>{tool}</li>
                            </ul>
                        ))}
                  </Col>
              </Row>
          </Container>
      </Container>
  );
}

export default ProfileSkills;