import * as React from "react";
import { FaGithub, FaLinkedin, } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function SocialeMedia () {
    return (
        <ul className="social-links">
            <li className="social-icons">
                <a
                    href="https://github.com/Bamboudorer"
                    target="_blank"
                    rel="noreferrer"
                    classename="social-icon-color social-icon-hover"
                >
                    <FaGithub className="social-single-icon" size={25}/>
                </a>
            </li>
            <li className="social-icons">
                <a
                    href="https://www.linkedin.com/in/lucas-techer/"
                    target="_blank"
                    rel="noreferrer"
                    classename="social-icon-color social-icon-hover"
                >
                    <FaLinkedin className="social-single-icon" size={25}/>
                </a>
            </li>
            <li className="social-icons">
                <a
                    href="mailto:lucas.techer@epitech.eu"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                >
                    <MdEmail className="social-single-icon" size={25}/>
                </a>
            </li>
        </ul>
    );
}

export default SocialeMedia;