import * as React from "react";
import {  Row, Col, Container} from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Lang from "../../translate/Lang";
// import { useTranslation } from "react-i18next";
// import '../../css/Footer.css';
import SocialeMedia from "./SocialMedia";

function Footer() {
    var year = new Date().getFullYear();

    // const { t } = useTranslation();

    return (
        <Container fluid className="footer">
            <Row>
                <Col md="4" className="footer-col">
                    <h3>Developed By Lucas Techer</h3>
                </Col>
                <Col md="4" className="footer-col">
                    <h3>Copyright © {year}</h3>
                </Col>
                <Col md="4" className="footer-col">
                    <SocialeMedia/>
                </Col>
            </Row>
        </Container>
    //     <footer className="footer">
    //         <Row>
    //             <Col>Games</Col>
    //             <Col>About</Col>
    //             <Col>Back To Top</Col>
    //         </Row>
    //         <Row>
    //             <Col>Copyright</Col>
    //         </Row>
    //    </footer>
    );
}

export default Footer;