import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import myImage from "../assets/Profile/panth.png"

function HomeMainTitle() {

  const { t } = useTranslation();

  return (
      <Container className="home-title">
          <Row>
            <Col md={6} className="home-title-header">
              {/* <p>Web developer and Game developer</p> */}
              <h3 style={{ paddingBottom: 15 }} className="home-title-job">
                {t('mainTitleHome.title')}
              </h3>
              <h1 className="home-title-name">
                <strong className="home-title-main-name">Lucas Techer</strong>
              </h1>
              <p className="home-title-description">
                {t('mainTitleHome.description')}
              </p>
            </Col>
            <Col md={5}>
              <img src={myImage} className="home-title-image" alt="avatar"/>
            </Col>
          </Row>
      </Container>
  );
}

export default HomeMainTitle;