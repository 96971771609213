import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Lang from "../../translate/Lang";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { FaDev } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";

function NavigationBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateColor] = useState(false);

  // const { t } = useTranslation();

  function changeScroll() {
    if (window.scrollY >= 20) {
      updateColor(true);
    } else {
      updateColor(false);
    }
  }

  window.addEventListener("scroll", changeScroll);

  return (
    <Navbar 
      expand="md"
      expanded={expand}
      fixed="top"
      className={navColour ? "navbar-expanded" : "navbar"}
    >
      <Container>
        
        <Navbar.Brand style={{ color: "white"}}>
          <FaDev style={{ marginBottom: "2px"}} /> L.T
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        />
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav className="me-auto"/>
          <Nav className="me-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link 
                as={Link}
                to="/"
                onClick={() => updateExpanded(false)}
                style={{ color: "white"}}
              >
                <AiOutlineHome /> Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/profile"
                onClick={() => updateExpanded(false)}
                style={{ color: "white"}}
              >
                <AiOutlineUser /> Profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/portfolio"
                onClick={() => updateExpanded(false)}
                style={{ color: "white"}}
              >
                <CgFileDocument/> Portfolio
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <Lang />
      </Container>
    </Navbar>
  );
}

export default NavigationBar;