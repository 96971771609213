import * as React from "react";
import HomePortfolio from '../components/HomePortfolio';
import { Container } from "react-bootstrap";

function Portfolio() {
  return (
    <Container className="home-section" id="portfolio">
      <HomePortfolio/>
    </Container>
  );
}

export default Portfolio;